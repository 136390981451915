// src/components/LoginToSpotify.js
import React from 'react';

const CLIENT_ID = process.env.REACT_APP_SPOTIFY_CLIENT_ID;
const REDIRECT_URI = encodeURIComponent(process.env.REACT_APP_REDIRECT_URI);
const AUTH_ENDPOINT = 'https://accounts.spotify.com/authorize';
const RESPONSE_TYPE = 'token';
const SCOPES = ['streaming', 'user-read-email', 'user-read-private', 'user-modify-playback-state']; // Add other scopes as needed

const LoginToSpotify = () => {
  const authUrl = `${AUTH_ENDPOINT}?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=${RESPONSE_TYPE}&scope=${encodeURIComponent(SCOPES.join(' '))}`;

  return (
    <div className="login-container">
      <a href={authUrl} className="login-link">Login to Spotify</a>
    </div>
  );
};

export default LoginToSpotify;
